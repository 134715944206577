import React, { useEffect, useState, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
} from "../../../util/Constant";
import Input from "../../../components/custom/Input";
import { map } from "lodash";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCurrencyFormat } from "../../../util/Util";
import ApiEngine from "../../../util/ApiEngine";
import moment from "moment";
import ContentHeader from "../../../components/contentheader/header";
import SweetAlert from "react-bootstrap-sweetalert";
import { setThemeName } from "../../../redux/AppAction";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import WithdrawByBankTransfer from "../transaction/WithdrawByBankTransfer";
import { heartBeat } from "../../../redux/AuthAction";
import Spinner from "react-bootstrap/Spinner";

/// <summary>
/// Author: -
/// </summary>
const WithdrawalMethods = (props) => {
	var { t, i18n } = useTranslation();
	const _dispatch = useDispatch();
	var _history = useHistory();
	var _userData = useSelector((state) => state.authState.userData);
	const [headerMsg, setHeaderMsg] = useState("");
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [countdownStr, setCountdownStr] = useState("");
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [transType, setTransType] = useState("");
	const [countdownTime, setCountdownTime] = useState();
	const _SECONDS_IN_MS = 1000;
	const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showWithdrawBankTransfer, setShowWithdrawBankTransfer] =
		useState(false);
	const [showWithdrawTopkash, setShowWithdrawTopkash] = useState(false);
	const [showWithdrawMethods, setShowWithdrawMethods] = useState(false);
	const [showWithdrawBCPay, setShowWithdrawBCPay] = useState(false);
	const [instantPayOption, setInstantPayOption] = useState([]);
	const [isInitFinished, setIsInitFinished] = useState(false);

	let withdrawMethods = [
		{
			image: require("../../../assets/img/new_icons/bank.png"),
			title: "BANK_TRANSFER",
			link: WebUrl._URL_WITHDRWAL_BY_BANK_TRANSFER,
		},
	];

	useEffect(() => {
		init();
	}, []);

	async function init() {
		_dispatch(heartBeat());

		setHeaderMsg("");

		var responseJson = await ApiEngine.get(ApiUrl._API_GET_PENDING_TRANSACTION);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY] != null) {
				clearTimeout(countdownTime);
				let tempS = parseInt(responseJson[ApiKey._API_DATA_KEY]["timeSpan"]);
				setTransType(responseJson[ApiKey._API_DATA_KEY]["transType"]);
				setTimeDiffSeconds(tempS);
				setCountdownPeriod(tempS < 0 ? 0 : tempS);
				setHasPendingTxn(true);
				setHeaderMsg(t("NEW_TXN_PROHIBITED"));
			}
			else {
				await getRestore();
			}
		}

		//KT this is very slow, we're not going to use Payment Gateway for AUD, might as well dont check and waste time
		/*let responseJson2 = await ApiEngine.get(
			ApiUrl._API_GET_USER_PAYMENT_GATEWAY_INFO
		);
		if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
			setInstantPayOption(responseJson2[ApiKey._API_DATA_KEY]);
		}

		if (withdrawMethods.length === 1)*/
		setShowWithdrawBankTransfer(true);

		setIsInitFinished(true);
	}

	async function getRestore() {
		let responseJson = await ApiEngine.get(ApiUrl._API_RESTORE_ALL_WALLET);

	}

	useEffect(() => {
		if (countdownPeriod > 0) {
			clearTimeout(countdownTime);
			setCountdownTime(
				setTimeout(() => {
					let tempPeriod = countdownPeriod - 1;
					setCountdownPeriod(tempPeriod);
					let tempX = moment.duration(tempPeriod);
					let tempMM = Math.floor(tempX["_milliseconds"] / 60);
					let tempSS = tempX["_milliseconds"] - tempMM * 60;
					setTimeDiffSeconds(tempX["_milliseconds"]);
					let tempStr =
						(tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
						" " +
						t("MINUTES") +
						" " +
						(tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
						" " +
						t("SECOND");
					setCountdownStr(tempStr);
				}, _SECONDS_IN_MS)
			);
		}
	}, [countdownPeriod]);

	return (
		<div>
			{!showWithdrawMethods && (
				<>
					{showConfirmation && (
						<SweetAlert
							warning
							confirmBtnText={t("CONFIRM")}
							confirmBtnBsStyle="danger"
							onConfirm={() => {
								setShowConfirmation(false);
							}}
							style={{
								backgroundColor: "#05102F",
								borderColor: "white !important",
								opacity: 0.9,
								color: "white",
								marginTop: "68%",
							}}
							cancelBtnBsStyle="#45ff8b"
						>
							{t(
								"PLEASE_WAIT_FOR_PREVIOUS_TRANSACTION_APPROVAL_OR_CONTACT_CUSTOMER_SERVICE"
							)}
						</SweetAlert>
					)}

					{/* {isInsufficientAmount && (
						<div className="mt-3 mb-3 reminder-yellow">
							<div className="first">
								<div className="first">
									<img
										src={require("../../../assets/img/new_icons/yellow.png")}
										className="img-responsive"
										alt="icon"
									/>
								</div>
								<div>
									<p className="font14 mb-0 f-weight-500">
										{t("AMOUNT_TOO_LESS")}
									</p>
								</div>
							</div>
						</div>
					)} */}

					<div>
						<div
							className={
								_userData["walletBalance"] > 0
									? "font14 f-weight-500 mb-3"
									: "font14 f-weight-500 mb-3 disabled-action"
							}
						>
							{withdrawMethods &&
								withdrawMethods.length > 1 && t("CHOOSE_ONE_FROM_THE_AVAILABLE_OPTIONS") }
						</div>
						{isInitFinished && (
							<div className="t3-settings-menu-list mt-3">
								{withdrawMethods &&
									withdrawMethods.length > 1 &&
									map(withdrawMethods, (item, index) => {
										return (
											<div
												className="t3-settings-menu-list-item"
												key={index}
												onClick={() => {
													if (hasPendingTxn) {
														setShowConfirmation(true);
													} else {
														setShowWithdrawMethods(true);
														if (item.title == "BANK_TRANSFER") {
															setShowWithdrawBankTransfer(true);
														}
														else if (item.title == "BCPAY") {
															setShowWithdrawBCPay(true);
														}
													}
												}}
												style={{ cursor: "pointer" }}
											>
												<div className="first">
													<div>
														<VIcon
															icon={themeIcons.settingsBank}
															className="theme-icon-size-20 vicon"
														/>
													</div>
													<div>
														<div>
															<div
																className={
																	_userData["walletBalance"] > 0
																		? "mb-0"
																		: "mb-0 disabled-action"
																}
															>
																{t(item.title)}
															</div>
															<span className="font12"></span>
														</div>
													</div>
												</div>
												<div className="second">
													<VIcon icon={themeIcons.angleRight} className="vicon" />
												</div>
											</div>
										);
									})}
							</div>
							)
						}
						{!isInitFinished && (
							<div
								style={{
									textAlign: "center",
									marginTop: "25px",
									marginBottom: "20px",
								}}
							>
								<Spinner
									animation="border"
									role="status"
									size="sm"
									style={{
										verticalAlign: "middle",
										width: "2rem",
										height: "2rem",
									}}
								>
									<span className="sr-only">{t("LOADING")}...</span>
								</Spinner>
							</div>
							)}
					</div>
				</>
			)}

			{showWithdrawBankTransfer && (
				<>
					<WithdrawByBankTransfer />
				</>
			)}
		</div>
	);
};

export default WithdrawalMethods;

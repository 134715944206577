import { map } from "lodash";
import React, { useEffect } from "react";
import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import PlaceholderLoading from "react-placeholder-loading";

const LoadingGames = (props) => {
    // Get values from props
    // const { rows, columns, coverHeight, coverWidth, padding, speed } = props;

    // Hardcoded values
    const rows = props.rows || 2;
    const columns = props.rows || 6;
    const coverHeight = 215;
    const coverWidth = 169;
    const padding = 10;
    const speed = 1;

    const coverHeightWithPadding = coverHeight + padding;
    const coverWidthWithPadding = coverWidth + padding;
    const initial = 35;
    const covers = Array(columns * rows).fill(1);

    useEffect(() => {}, []);

    return (
        <>
            <LazyLoad>
            {props.banner ? (
                <div className="mt-4 mb-4">
                    <PlaceholderLoading
                        shape="rect"
                        width={"100%"}
                        height={140}
                        colorStart="#333"
                        colorEnd="#636363"
                    />
                </div>
            ) : (
                ""
            )}
            {props.title ? (
                <div className="mt-4 mb-4">
                    <PlaceholderLoading
                        shape="rect"
                        width={100}
                        height={30}
                        colorStart="#333"
                        colorEnd="#636363"
                    />
                </div>
            ) : (
                ""
            )}
            <div
                className="t3-custom-game-list-box"
            >
                <div className="t3-game-list-box">
                    {map(covers, (item, index) => {
                        return (
                            <PlaceholderLoading
                                key={index}
                                shape="rect"
                                width={"100%"}
                                height={"100%"}
                                colorStart="#333"
                                colorEnd="#636363"
                            />
                        );
                    })}
            
                </div>
            </div>
            </LazyLoad>
        </>


    );
};



export default LoadingGames;

import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import DateTime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';
import { useSelector } from "react-redux";

const DateRangeFilter = (props) => {
    let _history = useHistory();
    const { t, i18n } = useTranslation();

    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();
    const [selectedOption, setSelectedOption] = useState({});
    const [openCalendar, setOpenCalendar] = useState(false);
    const [optionClicked, setOptionClicked] = useState(false);
    const [currMinDate, setCurrMinDate] = useState(moment());
    const [currMaxDate, setCurrMaxDate] = useState(moment());
    var _dateRef = useRef(null);

    const {
        startDate = sDate,
        endDate = eDate,
        setStartDate,
        setEndDate,
        minDate,
        maxDate,
        onStartDateChange,
        onEndDateChange,
        onSave,
        options = false,
        ...rest
    } = props;
    const _PREV_DAY = "PREV";
    const _NEXT_DAY = "NEXT";

    const _DATE_SELECTIONS = [
        {
            label: t("PREV_DAY"),
            from: _PREV_DAY,
        },
        {
            label: t("TODAY"),
            from: moment(),
            initialType: moment().format("DD-MM-YYYY") + t("TO") + moment().format("DD-MM-YYYY")
        },
        {
            label: t("YESTERDAY"),
            from: moment().subtract(1, 'day'),
            to: moment().subtract(1, 'day'),
            initialType: moment().subtract(1, "day").format("DD-MM-YYYY") + t("TO") + moment().subtract(1, "day").format("DD-MM-YYYY")
        },
        {
            label: t("THIS_WEEK"),
            from: moment().startOf('isoWeek'),
            initialType: moment().startOf("week").format("DD-MM-YYYY") + t("TO") + moment().endOf("week").format("DD-MM-YYYY")
        },
        {
            label: t("LAST_WEEK"),
            from: moment().subtract(1, 'week').startOf('isoWeek'),
            to: moment().subtract(1, 'week').endOf('isoWeek'),
            initialType: moment().subtract(1, "week").startOf("week").format("DD-MM-YYYY") + t("TO") + moment().subtract(1, "week").endOf("week").format("DD-MM-YYYY")
        },
        {
            label: t("THIS_MONTH"),
            from: moment().startOf('month'),
            initialType: moment().startOf("month").format("DD-MM-YYYY") + t("TO") + moment().endOf("month").format("DD-MM-YYYY")
        },
        {
            label: t("LAST_MONTH"),
            from: moment().startOf('month').subtract(1, 'month'),
            to: moment().startOf('month').subtract(1, 'month').endOf('month'),
            initialType: moment().startOf("month").subtract(1, "month").format("DD-MM-YYYY") + t("TO") + moment().startOf("month").subtract(1, "month").format("DD-MM-YYYY")
        },
        {
            label: t("NEXT_DAY"),
            from: _NEXT_DAY
        },
    ];

    useEffect(() => {

        let tempMinDate = props.minDate;
        let tempMaxDate = props.maxDate;
        if (props.minDate === undefined) {
            tempMinDate = moment().subtract(2, 'months');
        }
        if (props.maxDate === undefined) {
            tempMaxDate = moment();
        }
        setCurrMinDate(tempMinDate);
        setCurrMaxDate(tempMaxDate);
        let startDateMoment;
        let endDateMoment;
        if (selectedOption.from == _PREV_DAY) {
            startDateMoment = moment(startDate).subtract(1, 'day');
            endDateMoment = moment(startDate).subtract(1, 'day');
        } else if (selectedOption.from == _NEXT_DAY) {
            startDateMoment = moment(startDate).add(1, 'day');
            endDateMoment = moment(startDate).add(1, 'day');
        } else {
            startDateMoment = selectedOption.from ? selectedOption.from : moment();
            endDateMoment = selectedOption.to ? selectedOption.to : moment();
        }

        if (startDate === undefined || optionClicked) {
            setSDate(startDateMoment);
            if (typeof (setStartDate) !== "undefined") {
                props.setStartDate(startDateMoment);
            }
        }
        else
            setSDate(startDate);
        if (endDate === undefined || optionClicked) {
            setEDate(endDateMoment);
            if (typeof (setEndDate) !== "undefined") {
                props.setEndDate(endDateMoment);
            }
        }
        else
            setEDate(endDate);

        setOptionClicked(false);
    }, [selectedOption]);

    useEffect(() => {
        if (openCalendar) {
            _dateRef.current.openCalendar();
        }
    }, [openCalendar])

    function handleStartDate(date) {
        setSDate(date);
        props.setStartDate(date);
        setOpenCalendar(true);
        if (onStartDateChange) {
            onStartDateChange(date);
        }
    }

    function handleEndDate(date) {
        setEDate(date);
        props.setEndDate(date);
        if (onEndDateChange) {
            onEndDateChange(date);
        }
    }

    function handleSave() {
        if (onSave) {
            onSave(sDate, eDate);
        }
    }
    var sDateValid = function(current){
        return current.isSameOrAfter(currMinDate) && current.isSameOrBefore(currMaxDate);
    }

    function updateTypeOnSelection(selection) {
        if (props.typeOnSelect != undefined) {
            props.typeOnSelect(selection.initialType);
        }
    }
    var eDateValid = function(current){
        return current.isSameOrAfter(moment(sDate).startOf('day')) && current.isSameOrBefore(currMaxDate);
    }
    return (
        /*<div id={activeTheme === 0 ? "date-range-filter" : "date-range-filter-modern"}>*/
        <div id="date-range-filter">
            <div className="filter-grid-wrapper" style={{ "marginBottom": "0.5em" }}>
                <div>
                    <label className='mb-1'>Start Date</label>
                    <DateTime
                        inputProps={{ className: 'form-control leftAlignPicker', readOnly: true }}
                        name="startDate"
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect={true}
                        value={sDate}
                        isValidDate={sDateValid}
                        timeFormat={false}
                        onChange={handleStartDate} />
                </div>
                <div>
                    <label className='mb-1'>End Date</label>
                    <DateTime
                        inputProps={{ className: 'form-control', readOnly: true }}
                        name="endDate"
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect={true}
                        timeFormat={false}
                        value={eDate}
                        isValidDate={eDateValid}
                        ref={_dateRef}
                        onChange={handleEndDate} />
                </div>
                <button className="btn btn-brand text-nowrap d-none" onClick={() => { handleSave() }}>{t("SEARCH")}</button>
            </div>

            {
                props.options &&
                <div className="date-selection-wrapper">
                    {
                            _DATE_SELECTIONS.map((selection) => {
                            return <div key={selection.label}
                                onClick={() => { setOptionClicked(true); setSelectedOption(selection); updateTypeOnSelection(selection) }}
                                className={classNames("selection-item", {
                                    active: (selection.from !== _PREV_DAY && selection.from !== _NEXT_DAY &&
                                        (selectedOption.label === selection.label || (props.initialType !== undefined && selection.initialType === props.initialType)))
                                })}
                            >
                                {selection.label}
                            </div>
                        })
                    }
                </div>
            }
        </div>
    );
};

export default DateRangeFilter;
import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../../util/Constant";
import { useTranslation } from "react-i18next";
import {
    setBusy,
    setIdle,
    showResponseMessage,
    setDownlineSearchText,
    setDownlineQuery,
    setPageSize,
    setY,
    setActiveTab,
    setDownline,
    setDownlineModal,
} from "../../../redux/AppAction.js";
import { useForm, Controller } from "react-hook-form";
import ApiEngine from "../../../util/ApiEngine.js";
import { ModalBody, Spinner } from "reactstrap";
import moment from "moment";
import DateRangeFilter from "components/custom/DateRangeFilter";
import DownlineDetails from "../../template_1/home/DownlineDetails";

const DownlineSummary = (props) => {
    var { t } = useTranslation(LanguageKey._PRIMARY);
    var _history = useHistory();
    var _dispatch = useDispatch();
    var _location = useLocation();
    const { register, handleSubmit, errors, setValue, watch, control, trigger } =
        useForm();

    const [monthOptions, setMonthOptions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");

    const [selectedGroup, setSelectedGroup] = useState(0);
    const [downlineShow, setDownlineShow] = useState(false);

    const downlineState = useSelector((state) => state.appState.downline);
    const { memberData, recentData } = useSelector(
        (state) => state.appState.downline
    );

    const [memberLMData, setMemberLMData] = useState([]);
    const dateRangePickerOnClose = () => {
        setIsDateFilterOpen(false);
    };
    const [startDate, setStartDate] = useState(
        moment().startOf("week").format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("week").format("YYYY-MM-DD")
    );
    const [selectedDate, setSelectedDate] = useState("");
    const [queryProduct, setQueryProduct] = useState("");
    const [searchTrigger, setSearchTrigger] = useState(Date.now());
    const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState(
        moment().startOf("week").format("DD-MM-YYYY") +
        t("TO") +
        moment().endOf("week").format("DD-MM-YYYY")
    );
    const filterTypeOnSelect = (value) => {
        setSelectedFilterType(value);
    };

    const [newGen, setNewGen] = useState("");
    const [newAllGens, setNewAllGens] = useState("");
    const [totalGens, setTotalGens] = useState("");
    const [totalAllGen, setTotalAllGen] = useState("");
    const [numberOfGen, setNumberOfGen] = useState("");

    const [memberDetailUrl, setMemberDetailUrl] = useState("");
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [showDownlineDetail, setShowDownlineDetail] = useState(false);
    const [username, setUsername] = useState("");
    const [showDownlineLsit, setShowDownlineList] = useState(true)
    const isDownlineModal = useSelector((state) => state.appState.downlineModal);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        init();
        getReportDate();
    }, []);

    useEffect(() => {
        var months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];

        var month = months.indexOf(selectedMonth);
        var year = new Date().getFullYear();
        var targetedMonth = moment(new Date(year, month, 1)).format("MMM YY");
        getMemberDetails(targetedMonth);
    }, [selectedMonth]);

    useEffect(() => {
        // if (memberData === null && recentData === null) {
        //   _dispatch(setBusy());
        // } else {
        //   _dispatch(setIdle());
        // }
        getMemberDetails(startDate)
    }, [startDate, endDate]);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        await getRecentMember();
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getRecentMember() {
        setMemberDetailUrl(
            ApiUrl._API_GET_USER_MEMBER_LIST_LATEST +
            "?keyword=&directUserOnly=true"
        );

        //var responseJson = await ApiEngine.get(
        //	ApiUrl._API_GET_USER_MEMBER_LIST_LATEST +
        //		"?keyword=&count=100&directUserOnly=true"
        //);

        //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        //	_dispatch(
        //		setDownline({
        //			...downlineState,
        //			recentData: responseJson[ApiKey._API_DATA_KEY],
        //		})
        //	);
        //} else {
        //	_dispatch(
        //		showResponseMessage(
        //			responseJson[ApiKey._API_SUCCESS_KEY],
        //			responseJson[ApiKey._API_MESSAGE_KEY]
        //		)
        //	);
        //}

        //var startOfMonth = moment().startOf('month').format('DD-MM-YYYY HH:mm:ss');
        //var endOfMonth = moment().endOf('month').format('DD-MM-YYYY HH:mm:ss');
        setLoadingDetails(true);

        var responseJson2 = await ApiEngine.get(
            ApiUrl._API_GET_NUMBER_OF_GEN +
            "?startDate=" +
            startDate +
            "" +
            "&endDate=" +
            endDate +
            ""
        );
        setLoadingDetails(false);

        if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
            //setNewGen(responseJson2[ApiKey._API_DATA_KEY].data.newGen);
            //setNewAllGens(responseJson2[ApiKey._API_DATA_KEY].data.newAllGens);
            //setTotalGens(responseJson2[ApiKey._API_DATA_KEY].data.totalGen);
            //setTotalAllGen(responseJson2[ApiKey._API_DATA_KEY].data.totalAllGen);
            //setNumberOfGen(responseJson2[ApiKey._API_DATA_KEY].data.numberOfGen);
            setNewGen(responseJson2[ApiKey._API_DATA_KEY].data.newGen);
            setNewAllGens(responseJson2[ApiKey._API_DATA_KEY].data.newAllGens);
            setTotalGens(responseJson2[ApiKey._API_DATA_KEY].data.totalGens);
            setTotalAllGen(responseJson2[ApiKey._API_DATA_KEY].data.totalAllGen);
            setNumberOfGen(responseJson2[ApiKey._API_DATA_KEY].data.numberOfGens);
        }
        // _dispatch(setIdle());
    }

    async function getMemberDetails(targetedMonth) {
        //let prevData = [];
        //var responseJson = await ApiEngine.get(
        //	ApiUrl._API_GET_MEMBER_DETAILS_AND_DATE + "?targetedMonth=" + targetedMonth
        //);

        //if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        //	_dispatch(
        //		setDownline({
        //			...downlineState,
        //			memberData: responseJson[ApiKey._API_DATA_KEY],
        //		})
        //	);
        //} else {
        //	_dispatch(
        //		showResponseMessage(
        //			responseJson[ApiKey._API_SUCCESS_KEY],
        //			responseJson[ApiKey._API_MESSAGE_KEY]
        //		)
        //	);
        //}

        //var prevMonth = moment(targetedMonth, "MMM YY").subtract(1, "month").format("MMM YY");
        //var responseJson2 = await ApiEngine.get(
        //	ApiUrl._API_GET_MEMBER_DETAILS_AND_DATE + "?targetedMonth=" + prevMonth
        //);

        //if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
        //	setMemberLMData(responseJson2[ApiKey._API_DATA_KEY]);
        //}
        setLoadingDetails(true);

        var responseJson2 = await ApiEngine.get(
            ApiUrl._API_GET_NUMBER_OF_GEN +
            "?startDate=" +
            moment(startDate).startOf("day").format("YYYY-MM-DD") +
            "" +
            "&endDate=" +
            moment(endDate).startOf("day").format("YYYY-MM-DD") +
            ""
        );
        setLoadingDetails(false);

        if (responseJson2[ApiKey._API_SUCCESS_KEY]) {
            setNewGen(responseJson2[ApiKey._API_DATA_KEY].data.newGen);
            setNewAllGens(responseJson2[ApiKey._API_DATA_KEY].data.newAllGens);
            setTotalGens(responseJson2[ApiKey._API_DATA_KEY].data.totalGens);
            setTotalAllGen(responseJson2[ApiKey._API_DATA_KEY].data.totalAllGen);
            setNumberOfGen(responseJson2[ApiKey._API_DATA_KEY].data.numberOfGens);
        }
    }

    const getReportDate = async () => {
        var apiUrl2 = ApiUrl._API_GET_REPORT_OVERVIEW_SELECT_DATE;
        let test = await ApiEngine.get(apiUrl2);
        if (test[ApiKey._API_SUCCESS_KEY]) {
            var data2 = test[ApiKey._API_DATA_KEY];

            setMonthOptions(
                data2.report.map((bd) => {
                    return { label: bd.name, value: bd.month };
                })
            );
            var lg = data2["report"].length - 1;
            setSelectedMonth(data2["report"][lg]["month"]);
            setSelectedYear(data2["report"][lg]["year"]);
        }
    };

	return (
        <div className="t3-downline-modal-body mb-4" id="deposit-id">
            {showDownlineDetail && (
                <div>
                    <DownlineDetails username={username} setShowDownlineDetail={setShowDownlineDetail} showDownlineDetail={showDownlineDetail} setShowDownlineList={setShowDownlineList} />
                </div>

            )}
            {showDownlineLsit &&
                <div >
                    <DateRangeFilter
                        // isOpen={isDateFilterOpen}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onSave={(start, end) => {
                            setStartDate(start);
                            setEndDate(end);
                            getMemberDetails();
                        }}
                        options={true}
                        initialType={selectedFilterType}
                        // onClose={dateRangePickerOnClose}
                        typeOnSelect={filterTypeOnSelect}
                    />
                    {/* <div className="downline-gen-note mt-4 mb-3">* Gen = Generation</div> */}

                    <div className="reports-stats-box">
                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("NEW_GEN")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{newGen}</div>
                            )}
                        </div>

                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("NEW_ALL_GENS")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{newAllGens}</div>
                            )}
                        </div>
                    </div>
                    <div className="mt-3">
                        <h5>{t("UP_TO_NOW")}</h5>
                    </div>

                    <div className="reports-stats-box">
                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("TOTAL_GEN")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{totalGens}</div>
                            )}
                        </div>

                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("TOTAL_ALL_GENS")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{totalAllGen}</div>
                            )}
                        </div>

                        <div className="ro-box-1-1">
                            <div className="earn-stat-title mb-1">
                                <div className="first-title">{t("NUMBER_OF_GENS")}</div>
                            </div>
                            {loadingDetails ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    size="sm"
                                    style={{
                                        verticalAlign: "middle",
                                        width: "1.3rem",
                                        height: "1.3rem",
                                        margin: "5px 0px",
                                        paddingTop: "6px",
                                    }}
                                ></Spinner>
                            ) : (
                                <div className="earn-stat-number mb-1">{numberOfGen}</div>
                            )}
                        </div>
                    </div>
                </div>
            }

        </div>
	)
};

export default DownlineSummary;
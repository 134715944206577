import React, { lazy, Suspense } from "react";
import {
	Route,
	withRouter,
	Switch,
	Redirect,
	useParams
} from "react-router-dom";
import { 
	fetchRoute, 
	// _LOGIN_ROUTE2
} from "../../config/page-route.jsx";
import { PageSettings } from "../../config/page-settings.js";
import { connect } from "react-redux";
import Footer from "components/template_1/Footer.js";
import Header from "components/template_1/Header.js";
import reactManifest from "react-manifest";
import manifest from "../../manifest.json";
import LoadingPlaceholder from "components/template_1/LoadingPlaceholder.js";
import { find } from "lodash";
import DepositWithdrawalModal from "components/template_1/DepositWithdrawalModal.js";
import DownlineModal from "components/template_1/DownlineModal.js";
import ShareReferralModal from "components/template_1/ShareReferralModal.js";
import LoginModal from "components/template_1/LoginModal.js";
import {
	WebUrl,
	SessionKey,
	Language
} from "../../util/Constant";
import ApiEngine from "util/ApiEngine.js";
import ResetPasswordModal from "components/template_1/ResetPasswordModal.js";
import NotFound from "pages/template_1/NotFound.js";
import Login from "pages/template_1/Login.js";


class Content extends React.Component {
	constructor(props) {
		super(props);
		this._TIMEOUT_TIME = 60 * 60 * 1000;
		this.state = {
			template: props.template ?? "template_1",
			routes: null,
			currroutes: null,
			loginRoute: null,
			currentPage: this.props.history.location.pathname,
			bottomNavIndex: 0,
		};
	}

	ComponentRoute(routeComponent) {
		return routeComponent;
	}

	// CHRIS_LOCALE: Add locale definitions and functions
	VALID_LOCALES =  [ 'ab' , 'aa' , 'af' , 'ak' , 'sq' , 'am' , 'ar' , 'an' , 'hy' , 'as' , 'av' , 'ae' , 'ay' , 'az' , 'bm' , 'ba' , 'eu' , 'be' , 'bn' , 'bh' , 'bi' , 'bs' , 'br' , 'bg' , 'my' , 'ca' , 'km' , 'ch' , 'ce' , 'ny' , 'zh' , 'cu' , 'cv' , 'kw' , 'co' , 'cr' , 'hr' , 'cs' , 'da' , 'dv' , 'nl' , 'dz' , 'en' , 'eo' , 'et' , 'ee' , 'fo' , 'fj' , 'fi' , 'fr' , 'ff' , 'gd' , 'gl' , 'lg' , 'ka' , 'de' , 'ki' , 'el' , 'kl' , 'gn' , 'gu' , 'ht' , 'ha' , 'he' , 'hz' , 'hi' , 'ho' , 'hu' , 'is' , 'io' , 'ig' , 'id' , 'ia' , 'ie' , 'iu' , 'ik' , 'ga' , 'it' , 'ja' , 'jv' , 'kn' , 'kr' , 'ks' , 'kk' , 'rw' , 'kv' , 'kg' , 'ko' , 'kj' , 'ku' , 'ky' , 'lo' , 'la' , 'lv' , 'lb' , 'li' , 'ln' , 'lt' , 'lu' , 'mk' , 'mg' , 'ms' , 'ml' , 'mt' , 'gv' , 'mi' , 'mr' , 'mh' , 'ro' , 'mn' , 'na' , 'nv' , 'nd' , 'ng' , 'ne' , 'se' , 'no' , 'nb' , 'nn' , 'ii' , 'oc' , 'oj' , 'or' , 'om' , 'os' , 'pi' , 'pa' , 'ps' , 'fa' , 'pl' , 'pt' , 'qu' , 'rm' , 'rn' , 'ru' , 'sm' , 'sg' , 'sa' , 'sc' , 'sr' , 'sn' , 'sd' , 'si' , 'sk' , 'sl' , 'so' , 'st' , 'nr' , 'es' , 'su' , 'sw' , 'ss' , 'sv' , 'tl' , 'ty' , 'tg' , 'ta' , 'tt' , 'te' , 'th' , 'bo' , 'ti' , 'to' , 'ts' , 'tn' , 'tr' , 'tk' , 'tw' , 'ug' , 'uk' , 'ur' , 'uz' , 've' , 'vi' , 'vo' , 'wa' , 'cy' , 'fy' , 'wo' , 'xh' , 'yi' , 'yo' , 'za' , 'zu'];  // ISO 639-1

	isValidLocale(locale) {
		return this.VALID_LOCALES.includes(locale);
	}

	hasValidLocale(pathname) {
		const locale = WebUrl.getCurrentLocale();

		// console.log('this.isValidLocale(locale)', this.isValidLocale(locale))

		// Check if url ends with valid locale
		// Get locale from browser url
		const browserLocale = this.extractLocaleFromPathname(pathname);
		const regex = new RegExp(`^/${browserLocale}$`);
		
		// For condition where url ends with locale
		if(this.isValidLocale(browserLocale) && pathname.endsWith(`/${browserLocale}`) && pathname.match(regex)) {
			return `/${browserLocale}`
		}

		// For condition where url contains locale
		const regex2 = new RegExp(`^/${browserLocale}(/|$)`)
		if(this.isValidLocale(browserLocale) && pathname.startsWith(`/${browserLocale}`) && pathname.match(regex2)) {
			return `/${browserLocale}`
		} else {
			return false
		}

		// // Check if url contains valid locale
		// const regex2 = new RegExp(`^/${locale}(/|$)`);

		// if(this.isValidLocale(locale) && pathname.startsWith(`/${locale}`) && pathname.match(regex2)) {
		// 	return `/${locale}`
		// } else {
		// 	return false
		// }
	}

	extractLocaleFromPathname(pathname) {
		// Split the pathname by slashes and filter out empty strings
		const pathSegments = pathname.split('/').filter(segment => segment.trim() !== '');
		// Check if there's at least one segment that could be the locale
		if (pathSegments.length > 0) {
			return pathSegments[0];
		} else {
			return null; // or a default locale, depending on your application's needs
		}
	}

	PrivateRoute(index, routeData) {
		const isLoggedIn = this.props.authState.heartBeat;
		const sessionLocale = localStorage.getItem(SessionKey._LANGUAGE)
		const shouldRedirect = routeData.authRequired && !isLoggedIn;

		// CHRIS_LOCALE: Check if locale is valid, if not then redirect to valid locale
		// Get locale from browser url
		const { pathname, state } = this.props.history.location;
		// Check if url contains valid locale

		// console.log('this.props.location', this.props.location)

		if (this.hasValidLocale(pathname)) {
			// Update locale of session to this new locale
			// console.log('this.extractLocaleFromPathname(pathname)', this.extractLocaleFromPathname(pathname))
			localStorage.setItem(SessionKey._LANGUAGE, this.extractLocaleFromPathname(pathname));
		} else {
			// CHRIS_LOCALE: Remove locale from url first
			const currentReference = state?.reference;
			const currentLocale = WebUrl.getCurrentLocale();
			const regex = new RegExp(`^/${currentLocale}(/|$)`);
			const locationPathname = pathname.replace(regex, '/');

			const redirectPath = WebUrl.getLocaleUrl(locationPathname);
			// console.log('pathname', pathname)
			// console.log('locationPathname', locationPathname)
			// console.log('currentReference', currentReference)
			// console.log('redirectPath', redirectPath)
			// console.log('REDIRECT')

			// this.props.history.replace({
			// 	pathname: redirectPath,
			// 	state: { reference: currentReference }
			// });

			// CHRIS TODO: Problem with passing state reference, because of redirect - need to find a way without redirect?
			// this.props.history.replace doesn't change the url underneath, so it causes problems with API calls
			// this.props.history.push changes the URL so state doesn't get passed and GameList doesn't work
			// Redirect/push might affect other things as well

			return (
				<Redirect 
					to={{
						pathname: redirectPath,
						state: { reference: currentReference }
					}}
				/>
			);
		}

		return (
			<Route
				key={index}
				path={shouldRedirect ? this.state.loginRoute.path: routeData.path}
				exact={shouldRedirect ? this.state.loginRoute.exact : routeData.exact}
				render={(props) => {
					// <React.Suspense fallback={this.renderFallback()}>
					// 	{ shouldRedirect
					// 			? this.state.loginRoute.component
					// 			: this.ComponentRoute(routeData.component) }
					// </React.Suspense>

					if (shouldRedirect) {
						return <Redirect to={{
							pathname: this.state.loginRoute.path,
							state: { from: props.location }
						}} />	
					} else {
						return <React.Suspense fallback={this.renderFallback()}>
							{this.ComponentRoute(routeData.component)}
						</React.Suspense>
					}
				}}
				// component={
				//   routeData.authRequired && !this.props.authState.heartBeat
				//     ? this.state.loginRoute.component
				//     : routeData.component
				// }
				// exact={routeData.exact}
				// path={routeData.path}
				// component={routeData.component}
			/>
		);
	}

	renderFallback() {
		return this.state.template === "template_1" ? <LoadingPlaceholder /> : null;
	}

	componentWillUpdate() {
		if (this.currentPage !== this.props.history.location.pathname) {
			this.currentPage = this.props.history.location.pathname;
			this.resetTimeout();
		}
	}

	async loadData() {
		try {
			const loginRouteVar = await fetchRoute("template_1", "login");
			const routesVar = await fetchRoute("template_1", "all");

			this.setState({
				routes: routesVar,
				loginRoute: loginRouteVar,
				currroutes: null,
			});
			// Now you can work with the data
		} catch (error) {
			// Handle errors
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		this.loadData();

		/*if (this.state.template == "template_1") {
			if (this.props.history.location.pathname !== "/") {
				this.startTimeout = setTimeout(() => {
					window.location.reload();
				}, this._TIMEOUT_TIME);
			}
		}*/
		//KT TODO CHECK THIS CODE FEASIBILITY
	}

	componentWillMount() {
	}

	resetTimeout = () => {
		clearTimeout(this.startTimeout);

		if (this.state.template == "template_1") {
			if (
				this.currentPage !== "/" &&
				this.props.history.location.pathname !== "/"
			) {
				this.startTimeout = setTimeout(() => {
					window.location.reload();
				}, this._TIMEOUT_TIME);
			}
		}
	};

	render() {
		// if (this.state.routes == null) return false;
		// this.state.currroutes = this.state.routes.filter((route) => {
		// 	return route.path == this.props.history.location.pathname;
		// });

		if (this.state.routes == null) return false;

		// Optimization - Filter through routes to eliminate non-matching routes
		this.state.currroutes = this.state.routes.filter((route) => {
			let pathname = route.path;
			let currRoutePath = this.props.history.location.pathname;

			 // Remove leading and trailing slashes for easier comparison
			 const normalizedPathname = pathname.replace(/^\/|\/$/g, '');
			 const normalizedCurrRoutePath = currRoutePath.replace(/^\/|\/$/g, '');
		 
			 // Split paths into segments
			 const routeSegments = normalizedPathname.split('/');
			 const pathSegments = normalizedCurrRoutePath.split('/');
		 
			 // Check for exact matches for non-parameterized routes
			 if (route.exact && normalizedPathname === normalizedCurrRoutePath) {
				 return true;
			 }
		 
			 // Check for locale parameterized routes
			 if (pathname.includes(':locale')) {
				 const localeSegment = pathSegments[0]; // Assuming the locale is the first segment
				 if (this.isValidLocale(localeSegment)) {
					 // Rebuild the path without the locale for further matching
					 const rebuiltPath = pathSegments.slice(1).join('/');
					 const rebuiltRoutePath = routeSegments.slice(1).join('/');
					 if (rebuiltPath === rebuiltRoutePath || pathname.endsWith(':slug')) {
						 return true;
					 }
				 }
			 }
		 
			 // Handling other parameterized routes that might contain slugs
			 if (!route.exact && pathname.includes(':slug')) {
				 return true; // Placeholder logic; refine this based on actual route structure
			 }

			// // Exact match for non-parameterized routes
			// if (route.exact && pathname === currRoutePath) {
			// 	return true;
			// }

			// if(pathname.includes(':locale')) return true

			// if (!route.exact && pathname.includes(':slug')) {
			// 	// const paramRegex = new RegExp(`/^\/${currRoutePath}\/[^/]+$`); // Adjust this regex as needed for your parameterized routes
			// 	// if (paramRegex.test(currRoutePath)){
			// 	// 	console.log('yes')
			// 	// 	return true;// Match parameterized routes

			// 	// }
			// 	return true
			// }

			return false;
		})

		return (
			<PageSettings.Consumer>
				{({
					pageContentFullWidth,
					pageContentClass,
					pageContentInverseMode,
				}) => (
					<div className="page-container">
						<div
							className={
								"content " +
								(pageContentFullWidth ? "content-full-width " : "") +
								(pageContentInverseMode ? "content-inverse-mode " : "") +
								pageContentClass
							}
						>
							<Header />

							{/* BrowserRouter located in index.js */}
							<Switch>
								{/* <Route exact path="/" component={Login} /> */}

								{this.state.currroutes.map((route, index) => {
									return this.PrivateRoute(index, route);
								})}

								{/* Catch all undefined routes */}
								<Route component={NotFound} />
							</Switch>

							<DepositWithdrawalModal />

							<DownlineModal />

							<ShareReferralModal />

							<LoginModal />
							<ResetPasswordModal />

							<Footer />
						</div>
					</div>
				)}
			</PageSettings.Consumer>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
});

export default connect(mapStateToProps, null)(withRouter(Content));

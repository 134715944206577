import React, { useState, useEffect } from "react";

const Pagination = (props) => {
    const { totalPages, getDownlineMemberData, searchKeyword, isResetCurrentPage, setIsChangeTab } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const pageLimit = 5;
    const startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
    const endPage = Math.min(totalPages, startPage + pageLimit - 1);

    const adjustedStartPage = endPage - startPage + 1 < pageLimit ? Math.max(1, endPage - pageLimit + 1) : startPage;

    useEffect(() => {
        if (isResetCurrentPage) {
            resetCurrentPage();
            setIsChangeTab(false);
        }
    }, [isResetCurrentPage]);

    const goToPage = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            getDownlineMemberData(pageNumber, searchKeyword);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            goToPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            goToPage(currentPage + 1);
        }
    };

    function resetCurrentPage() {
        setCurrentPage(prev => 1);
    }

    return (
        <div className="pagination-bar">
            <button className="arrow-btn" onClick={previousPage} disabled={currentPage === 1}><span>&#8249;</span></button>
            <div className={"pagination-number-bar"}>
            {[...Array(endPage - adjustedStartPage + 1).keys()].map((_, index) => {
                const pageNumber = adjustedStartPage + index;
                return (
                    
                        <button
                            key={pageNumber}
                            className={"page-btn" + (currentPage === pageNumber ? " active" : "")}
                            onClick={() => goToPage(pageNumber)}
                        >
                            {pageNumber}
                        </button>
                )
            })}
            </div>
            <button className="arrow-btn" onClick={nextPage} disabled={currentPage === totalPages}>&#8250;</button>
        </div>
    );
};

export default Pagination;
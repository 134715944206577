import {
  _IDLE,
  _BUSY,
  _ALERT_TYPES,
  _API_CALLED,
  _DOWNLINE_SEARCH,
  _DOWNLINE_QUERY,
  _REFERRAL_SEARCH,
  _REFERRAL_QUERY,
  _SET_Y,
  _SET_PAGESIZE,
  _SET_ACTIVE_TAB,
  _IS_KEEPED_LOGIN,
  _GAME_SEARCH,
  _GAME_QUERY,
  _SET_BOTTOM_TAB_INDEX,
  _BIND_SETTING,
  _BIND_MEMBER,
  _BIND_MEMBER_WALLET,
  _BIND_MEMBER_WALLET_BALANCE,
  _BIND_GAMES,
  _BIND_GAMES_RECOMMENDED,
  _BIND_GAMES_FEATURED,
  _SET_PAYMENT_LIST,
  _SET_WALLET_INFO,
  _SET_OPEN_MENU,
  _SET_REPORT_OVERVIEW,
  _SET_DOWNLINE,
  _SET_BANNERS,
  _SET_DEPOSIT_MODAL,
  _SET_DEPOSIT_TAB,
  _SET_LOGIN_MODAL,
  _SET_REGISTER_MODAL,
  _SET_PROVIDER,
  _SET_LATEST_BETS,
  _SET_GAME_LOBBY_MODAL,
  _SET_RESET_PASSWORD,
  _SET_DOWNLINE_MODAL,
  _SET_SHARE_REFERRAL_MODAL,
  _BUSY_LOADING,
  _SET_PROFILE_MODAL,
  _SET_SEO_CONTENT,
} from "./AppAction";
import { stringIsNullOrEmpty } from "../util/Util";
import i18n from "../localization/i18n";

const _INITIAL_STATE = {
  brandName: "",
  isBusy: false,
  isBusyLoading: false,
  message: i18n.t("LOADING"),
  alert: {},
  iconEnabled: true,
  apiCalledResponse: {},
  savedText: "",
  savedReferralText: "",
  gameSavedText: "",
  savedQuery: "", // KT: DEFAULT REMOVED
  savedReferralQuery: "", // KT: DEFAULT REMOVED
  gameQuery: "", // KT: DEFAULT REMOVED
  y: 0,
  pageSize: 10,
  activeTab: 0,
  bottomTabIndex: 0,
  member: [],
  games: [],
  gamesRecommended:{
    slotGamesCount:0,
    slotGames:[],
    casinoGamesCount:0,
		casinoGames:[]
  },
  gamesFeatured:{
    slotGamesCount:0,
    slotGames:[],
  },
  payments: [],
  walletBalance: 0,
  walletInfo: [],
  reportOverview: {
    displayData: [],
    newDisplayData: [],
    totalTransferToWallet: 0,
    totalTransferToWalletLM: 0,
    totalTransferToMember: 0,
    totalTransferToMemberLM: 0,
    totalBetAmount: 0,
    totalBetAmountLM: 0,
    totalCommission: 0,
    totalCommissionLM: 0,
    totalVipCommission: 0,
    totalBonus: 0,
  },
  downline: {
    memberData: null,
    recentData: null,
  },
  banners: [],
  themeName: "template1",
  openMenu: true,
  depositModal:false,
  depositTab:1,
  loginModal:false,
  gameLobbyModal:false,
  registerModal:false,
  provider:[],
  channels:[],
  latestBets:[],
  walletsDetails:{
    currentWalletBalance : 0,
    bonusWalletBalance : 0,
    isPromoCleared :false,
    isBonusWalletRestored : false,
    isValidForPromotion : false,
    isActivedPromoExist: false,
    promoMaxWithdrawal: false,
  },
  companyIcons:[],
  isResetPassword:false,
  downlineModal:false,
  shareReferralModal:false,
  companyCurrency:"$",
  depositPresetAmt:"30,50,100,200,500,1000",
  htmlStyle: false,
  chatSphere: [],
  languages: [],
  profileModal: false,
  seoContent:{
    pageName:"",
    seoTitle:"",
    seoDescription:""
  }
};

var _busyCount = 0;

export default (state = _INITIAL_STATE, action) => {
  switch (action.type) {
    case _BUSY:
      _busyCount++;
      return {
        ...state,
        isBusy: true,
        message: stringIsNullOrEmpty(action.message)
          ? i18n.t("LOADING")
          : action.message,
        iconEnabled:
          action.iconEnabled == null
            ? _INITIAL_STATE.iconEnabled
            : action.iconEnabled,
      };
      break;
    case _IDLE:
      _busyCount = Math.max(--_busyCount, 0);
      return {
        ...state,
        isBusy: _busyCount != 0,
      };
      break;
    case _ALERT_TYPES.SET_ALERT:
      return {
        ...state,
        alert: Object.keys(state.alert) == 0 ? action.alert : state.alert,
      };
      break;
    case _ALERT_TYPES.NONE:
      return {
        ...state,
        alert: {},
      };
      break;
    case _API_CALLED:
      return {
        ...state,
        apiCalledResponse: action.data,
      };
      break;
    case _DOWNLINE_SEARCH:
      return {
        ...state,
        savedText: action.data,
      };
      break;
    case _DOWNLINE_QUERY:
      return {
        ...state,
        savedQuery: action.data,
      };
      break;
    case _REFERRAL_SEARCH:
      return {
        ...state,
        savedReferralText: action.data,
      };
      break;
    case _REFERRAL_QUERY:
      return {
        ...state,
        savedReferralQuery: action.data,
      };
      break;
    case _GAME_SEARCH:
      return {
        ...state,
        gameSavedText: action.data,
      };
      break;
    case _GAME_QUERY:
      return {
        ...state,
        gameQuery: action.data,
      };
      break;
    case _SET_Y:
      return {
        ...state,
        y: action.y,
      };
    case _SET_PAGESIZE:
      return {
        ...state,
        pageSize: action.data,
      };
      break;
    case _SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.data,
      };
      break;
    case _SET_BOTTOM_TAB_INDEX:
      return {
        ...state,
        bottomTabIndex: action.bottomTabIndex,
      };
      break;
    case _BIND_MEMBER:
      return {
        ...state,
        member: action.member,
        walletBalance: action.walletBalance,
        walletsDetails: action.walletsDetails,
      };
      break;
    case _BIND_MEMBER_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.walletBalance,
        walletsDetails: action.walletsDetails,

      };
      break;
    case _BIND_MEMBER_WALLET:
      return {
        ...state,
        walletsDetails: action.walletsDetails,
      };
    case _BIND_GAMES:
      return {
        ...state,
        games: action.games,
      };
      break;
    case _BIND_GAMES_RECOMMENDED:
      return {
        ...state,
        gamesRecommended: {
          slotGamesCount: action.slotGamesCount,
          slotGames: action.slotGames,
          casinoGamesCount: action.casinoGamesCount,
	      casinoGames: action.casinoGames
        }
      };
      break;
    case _BIND_GAMES_FEATURED:
      return {
        ...state,
        gamesFeatured: {
          slotGamesCount: action.slotGamesCount,
          slotGames: action.slotGames
        }
      };
      break;
    case _SET_PAYMENT_LIST:
      return {
        ...state,
        payments: action.payments,
      };
      break;
    case _SET_OPEN_MENU:
      return {
        ...state,
        openMenu: action.data,
      };
      break;
    case _SET_WALLET_INFO:
      return {
        ...state,
        walletInfo: action.data,
      };
      break;

    case _SET_REPORT_OVERVIEW:
      state.reportOverview.displayData = action.displayData;
      state.reportOverview.newDisplayData = action.newDisplayData;
      state.reportOverview.totalTransferToWallet = action.totalTransferToWallet;
      state.reportOverview.totalTransferToWalletLM = action.totalTransferToWalletLM;
      state.reportOverview.totalTransferToMember = action.totalTransferToMember;
      state.reportOverview.totalTransferToMemberLM = action.totalTransferToMemberLM;
      state.reportOverview.totalBetAmount = action.totalBetAmount;
      state.reportOverview.totalBetAmountLM = action.totalBetAmountLM;
      state.reportOverview.totalCommission = action.totalCommission;
      state.reportOverview.totalCommissionLM = action.totalCommissionLM;
      state.reportOverview.totalVipCommission = action.totalVipCommission;
      state.reportOverview.totalBonus = action.totalBonus;

      return {
        ...state,
      };
      break;
    case _SET_DOWNLINE:
      state.downline.memberData = action.memberData;
      state.downline.recentData = action.recentData;
      return {
        ...state,
      };
      break;
    case _SET_BANNERS:
      return {
        ...state,
        banners: action.banners,
      };
      break;
    case _SET_DEPOSIT_MODAL:
      return {
        ...state,
        depositModal: action.depositModal,
        depositTab: action.depositTab
      };
      break;
    case _SET_DOWNLINE_MODAL:
      return {
        ...state,
        downlineModal: action.downlineModal,
      };
      break;
    case _SET_SHARE_REFERRAL_MODAL:
      return {
        ...state,
        shareReferralModal: action.shareReferralModal,
      };
      break;
    case _SET_DEPOSIT_TAB:
      return {
        ...state,
        depositTab: action.depositTab
      };
      break;
    case _SET_LOGIN_MODAL:
      return {
        ...state,
        loginModal: action.loginModal
      };
      break;
    case _SET_GAME_LOBBY_MODAL:
      return {
        ...state,
        gameLobbyModal: action.gameLobbyModal
      };
      break;
    case _SET_REGISTER_MODAL:
      return {
        ...state,
        registerModal: action.registerModal
      };
      break;
    case _SET_PROVIDER:
      return {
        ...state,
        provider: action.provider
      };
      break;
    case _SET_LATEST_BETS:
      return {
        ...state,
        latestBets: action.latestBets
      };
      break;
    case _SET_RESET_PASSWORD:
      return {
        ...state,
        isResetPassword: action.isResetPassword
      };
      break;

    case _BUSY_LOADING:
      return {
        ...state,
        isBusyLoading: action.isBusyLoading,
      };
      break;
    case _SET_PROFILE_MODAL:
      return {
        ...state,
        profileModal: action.profileModal
      };
      break;
    case _SET_SEO_CONTENT:
      state.seoContent.pageName = action.pageName;
      state.seoContent.seoTitle = action.seoTitle;
      state.seoContent.seoDescription = action.seoDescription;

      return {
        ...state,
      };
      break;
    case _BIND_SETTING:
      return {
        ...state,
        depositPresetAmt: action.depositPresetAmt != null ? action.depositPresetAmt : "30,50,100,200,500,1000", // MULTITAB THINGY
        companyCurrency: action.companyCurrency, // Account/GetCompanyDetails
        companyIcons: action.companyIcons,
        channels: action.channels,
        htmlStyle: action.htmlStyle,
        chatSphere: action.chatSphere,
        languages: action.languages,
        brandName: action.brandName
      };
    
    default:
      return state;
      break;
  }
};

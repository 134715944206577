import React, { useEffect, useState, useRef } from "react";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import { map, debounce, find, filter, upperCase, findIndex, get } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	SessionKey,
	AlertTypes,
} from "../../util/Constant";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import PinInput from "react-pin-input";
import Input from "../../components/custom/Input";
import { useForm } from "react-hook-form";
import {
	setBusy,
	setIdle,
	setLoginModal,
	setProvider,
	showMessage,
	bindCompanyIcon,
	setDepositModal,
} from "../../redux/AppAction.js";
import SecureLS from "secure-ls";
import ApiEngine from "../../util/ApiEngine.js";
import { stringIsNullOrEmpty } from "../../util/Util";

const Footer = (props) => {
	var { t } = useTranslation();
	var _history = useHistory();
	var _dispatch = useDispatch();
	const location = useLocation();
	var ls = new SecureLS();
	const search = window.location.search;
	const params = new URLSearchParams(search);

	const openMenu = useSelector((state) => state.appState.openMenu);
	const [currentWalletBalance, setCurrentWalletBalance] = useState(0);
	const memberData = useSelector((state) => state.appState.member);
	var { heartBeat } = useSelector((state) => state.authState);

	const [_username, _setUsername] = useState("");

	const _SECONDS_IN_MS = 1000;
	const [TACtimer, setTACtimer] = useState(setTimeout(() => {}, 60000));
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const [TACcountdownPeriod, setTACCountdownPeriod] = useState(0);

	const [_email, _setEmail] = useState("");
	const [_emailText, _setEmailText] = useState("");
	const channels = useSelector((state) => state.appState.channels);

	const [selectedChannel, setSelectedChannel] = useState();
	const [toggleModal, setToggleModal] = useState();
	const [openMobileSideMenu, setOpenMobileSideMenu] = useState(false);

	const providerList = useSelector((state) => state.appState.provider);
	const companyIcons = useSelector((state) => state.appState.companyIcons);
	const [sideMenuList, setSideMenuList] = useState();
	const [logo, setLogo] = useState();
	const [alertIcon, setAlertIcon] = useState();
	const [tabActive, setTabActive] = useState();
	const [bottomNavList, setBottomNavList] = useState();

	var brandName = useSelector((state) => state.appState.brandName);
	var totalPromoClaimable = useSelector((state) =>
		state.authState.heartBeat ? state.authState.userData.totalPromoClaimable : 1
	);
	var unreadLivechat = useSelector((state) =>
		state.authState.heartBeat ? state.authState.userData.totalUnreadLivechat : 1
	);

	const [footerSections, setFooterSections] = useState([]);
	const [sectionSettings, setSectionSettings] = useState({});

	useEffect(() => {
		if (companyIcons.length > 0) {
			getCompanyIcon();
		}
	}, [companyIcons]);

	useEffect(() => {
		if (
			location.pathname === WebUrl._URL_PROMOTION ||
			location.pathname === WebUrl._URL_PROMOTION_INFO ||
			location.pathname.includes("/promo/")
			//KT: Alamak two ppl do same, if use state?, user directly go into link I think cant get "Promotion"
			//location.pathname == WebUrl._URL_PROMOTION ||
			//location.pathname == WebUrl._URL_PROMOTION_INFO ||
			//location.state?.reference == "Promotion"
		) {
			let navIndex = findIndex(bottomNavList, {
				pathname: WebUrl._URL_PROMOTION,
			});
			setTabActive(navIndex);
		} else if (
			location.pathname === WebUrl._URL_REFERRAL ||
			location.pathname === WebUrl._URL_LIVECHAT ||
			location.pathname === WebUrl._URL_SETTINGS
		) {
			let navIndex = findIndex(bottomNavList, {
				pathname: location.pathname,
			});
			setTabActive(navIndex);
		} else {
			setTabActive(0);
		}
	}, [location]);

	useEffect(() => {
		if (countdownPeriod > 0) {
			setTimeout(() => {
				let tempPeriod = countdownPeriod - 1;
				setCountdownPeriod(tempPeriod);
			}, _SECONDS_IN_MS);
		}
	}, [countdownPeriod]);

	useEffect(() => {
		if (TACcountdownPeriod > 0) {
			setTACtimer(
				setTimeout(() => {
					let tempPeriod = TACcountdownPeriod - 1;
					setTACCountdownPeriod(tempPeriod);
				}, _SECONDS_IN_MS)
			);
		}
	}, [TACcountdownPeriod]);

	useEffect(() => {
		if (selectedChannel) {
			if (!stringIsNullOrEmpty(selectedChannel.attachment)) {
				setToggleModal(true);
			}
		}
	}, [selectedChannel]);

	useEffect(() => {
		setProvider(_dispatch);
		getFooterSections();
	}, []);

	function handleClick(channel) {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: "chat",
					//url: `${channel.actionUrl}`,
					url:
						channel.actionUrl.match(/live-chat/i) != null
							? `${window.location.origin}${channel.cta}`
							: `${channel.cta}`,
				})
			);
		} else {
			if (!stringIsNullOrEmpty(channel.cta)) {
				setToggleModal(false);
				window.location.href = channel.cta;
			} else {
				setSelectedChannel(channel);
				setToggleModal(true);
			}
		}
	}

	async function getCompanyIcon() {
		_dispatch(setBusy());
		const sideNavList = filter(companyIcons, { iconCategory: "side_nav" });
		if (sideNavList) {
			setSideMenuList(sideNavList);
		}

		const bottomNavList = filter(companyIcons, { iconCategory: "bottom_nav" });
		if (bottomNavList) {
			const bottomNav = [];

			bottomNavList.map((x) => {
				bottomNav.push({
					title: x.iconName.replace(/\s*\([^)]*\)\s*$/, ""),
					pathname: x.pathname,
					activeImage: x.iconImage,
					inactiveImage: x.inactiveIconImage,
					subLink: [],
				});
			});
			setBottomNavList(bottomNav);
		}

		const logo = find(companyIcons, { iconCategory: "logo" });
		if (logo) {
			setLogo(logo.iconImage);
		}

		const alert = find(companyIcons, { iconCategory: "alert" });
		if (alert) {
			setAlertIcon(alert.iconImage);
		}

		_dispatch(setIdle());
	}

	const getTempID = () => {
		var tempId = "";

		if (!heartBeat) {
			if (localStorage.getItem(SessionKey._TEMP_GUID) !== "") {
				tempId = localStorage.getItem(SessionKey._TEMP_GUID);
			}
			if (tempId == "undefined" || tempId == null) tempId = "";
		}

		return tempId;
	};

	const currentYear = new Date().getFullYear();

	function handleClick(channel) {
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(
				JSON.stringify({
					action: "chat",
					//url: `${channel.actionUrl}`,
					url:
						channel.actionUrl.match(/live-chat/i) != null
							? `${window.location.origin}${channel.cta}`
							: `${channel.cta}`,
				})
			);
		} else {
			if (!stringIsNullOrEmpty(channel.cta)) {
				setToggleModal(false);
				// window.location.href = channel.cta;
				window.open(channel.cta, "_blank");
			} else {
				setSelectedChannel(channel);
				setToggleModal(true);
			}
		}
	}

	function validateFooterSections(sections) {
		if (typeof sections.id !== "number") return false;
		if (typeof sections.title !== "string") return false;
		if (typeof sections.settings !== "string") return false;

		const sectionJson = JSON.parse(sections.settings);

		if (typeof sectionJson !== "object") return false;

		const result = sectionJson.every((x) => {
			if (typeof x.type !== "string") return false;
			if (typeof x.blocks !== "object") return false;
			if (typeof x.block_order !== "object") return false;
			if (typeof x.settings !== "object") return false;
			return true;
		});

		if (!result) return false;

		return true;
	}

	async function getFooterSections() {
		try {
			let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_THEME_SECTION_BY_HANDLE + "?handle=footer"
			);
			if (
				responseJson[ApiKey._API_SUCCESS_KEY] &&
				Object.keys(responseJson[ApiKey._API_DATA_KEY]).length > 0
			) {
				let footerSections = responseJson[ApiKey._API_DATA_KEY];

				if (validateFooterSections(footerSections)) {
					setFooterSections(footerSections);
				} else {
					// console.error("Invalid footer section structure", footerSections);
					// Handle invalid structure, e.g., set an error state or fallback
				}
			}
		} catch (error) {
			// console.error("Error fetching footer sections:", error);
			// Handle error, e.g., set an error state or retry
		}
	}

	const sanitizeUrls = (settings) => {
		if (!settings.blocks) {
			return settings;
		}

		settings.blocks = Object.entries(settings.blocks).reduce(
			(acc, [block_id, block]) => {
				if (block && block.type === "link_list") {
					Object.entries(block.settings.links).forEach(([link_key, link]) => {
						if (!link.url) return; // Skip if URL is missing

						// Sanitize URL
						link.url = link.url.replace("http://", "https://");

						// CHRIS: Don't sanitize here - we should sanitize and redirect at the app level in case people browse to http://
						// if (!link.url.match(/^https?:\/\//)) {
						// 	link.url = 'https://' + link.url;
						// }
					});
				} else if (block && block.type === "image") {
					if (typeof block.settings.image === "string") {
						block.settings.image = block.settings.image.replace(
							"http://",
							"https://"
						);
					}
				}
				acc[block_id] = block;
				return acc;
			},
			{}
		);

		return settings;
	};

	useEffect(() => {
		if (Object.keys(footerSections).length) {
			var parsedSettings = JSON.parse(footerSections.settings);

			setSectionSettings(sanitizeUrls(parsedSettings[0]));
		}
	}, [footerSections]);

	return (
		<>
			<div
				className="t3-content-container footer-container"
				style={{ minHeight: "auto" }}
			>
				<div className={openMenu ? "left active" : "left"}></div>
				<div className={openMenu ? "right active" : "right"}>
					<div className="t3-footer">
						{/* 
							TODO: - Chris
							- Create a 2nd style for footer navigation (to accommodate for menus with very few links)
							- Single line centered navigation menu
						*/}
						{sectionSettings &&
							sectionSettings.block_order &&
							sectionSettings.block_order.length > 0 && (
								<div className="t3-navigation-footer">
									{sectionSettings.block_order.map((block_id, index) => {
										const block = find(sectionSettings.blocks, {
											id: block_id,
										});

										if (block.type === "link_list") {
											return (
												<div className="t3-footer-nav-group" key={index}>
													<div className="t3-footer-nav-title">
														{block.settings.heading}
													</div>
													{Object.keys(block.settings.links).map(
														(link_key, link_index) => {
															const link = block.settings.links[link_key];
															return (
																// <Link to={link.url} className="t3-footer-nav-item">
																// 	{/* <i className="fa fa-chevron-right fa-fw text-inverse"></i>  */}
																// 	{link.name}
																// </Link>

																// TODO: Chris
																// Add a checker link.internalLink to see if the link is internal or external
																// If external, use <a href>, if internal, use <Link to>

																<a
																	key={link_index}
																	href={link.url}
																	className="t3-footer-nav-item"
																>
																	{link.name}
																</a>
															);
														}
													)}
												</div>
											);
										} else if (block.type === "image") {
											return (
												<div className="t3-footer-nav-group" key={index}>
													{/* <Link to={block.url} className="t3-footer-nav-item"> */}
													{/* </Link> */}
													<a href={block.url} className="t3-footer-nav-item">
														{block &&
														typeof block.settings.image === "string" ? (
															<img
																src={block.settings.image.replace(
																	"http://",
																	"https://"
																)}
																className="img-responsive"
																alt="bottomNav"
															/>
														) : null}
													</a>
												</div>
											);
										} else if (block.type === "rich_text") {
											return (
												<div key={index} className="t3-footer-nav-group">
													<div className="t3-footer-nav-title">
														{block.settings.heading}
													</div>
													<div
														dangerouslySetInnerHTML={{
															__html: block.settings.content,
														}}
														onClick={() => {
															window.location.href = block.url;
														}}
													></div>
												</div>
											);
										} else {
											return null;
										}
									})}
								</div>
							)}
						<div className="t3-top-footer">
							<div className="first">
								{providerList &&
									providerList.length &&
									map(providerList, (item, index) => {
										return (
											item && (
												<div key={index} className="t3-footer-logo-item">
													<div className="first">
														<img
															src={item}
															className="img-responsive"
															alt="providers"
														/>
													</div>
												</div>
											)
										);
									})}
							</div>
							<div className="second">
								<div className="t3-footer-sm-box">
									{channels.length === 0 ? (
										<div className="spinner-wrapper">
											<Spinner
												className="dynamic-table-spinner"
												animation="border"
												role="status"
												size="sm"
												style={{
													verticalAlign: "middle",
													width: "2rem",
													height: "2rem",
												}}
											></Spinner>
										</div>
									) : (
										channels &&
										channels.length > 0 &&
										map(channels, (item, index) => {
											return (
												<div
													key={index}
													className="t3-footer-sm-item"
													onClick={() => {
														if (!stringIsNullOrEmpty(item.cta)) {
															handleClick(item);
														}
													}}
												>
													<div className="">
														<img
															src={item.image}
															className="img-responsive"
															alt="social media"
														/>
													</div>
												</div>
											);
										})
									)}
								</div>
								<p>{t("FOOTER_NOTES")}</p>
							</div>
						</div>
						<div className="t3-copyright">
							Copyright @{currentYear} {brandName}. All rights reserved.
						</div>
					</div>
				</div>
			</div>

			{/* /////////t3 footer */}

			{/* /////////t3 mobile bottom nav */}
			<div className="t3-bottom-nav">
				{bottomNavList &&
					bottomNavList.length > 0 &&
					map(bottomNavList, (item, index) => {
						return (
							<div
								key={index}
								className={
									"t3-bottom-nav-item " + (tabActive === index ? "active" : "")
								}
								onClick={() => {
									_dispatch(setDepositModal(false));
									if (heartBeat) {
										_history.replace({
											pathname: item.pathname,
											state: {
												reference: item.state,
											},
										});
										localStorage.setItem("defaultActiveNav", index);
									} else {
										if (item["pathname"] !== "/settings") {
											_history.replace({
												pathname: stringIsNullOrEmpty(item["pathname"])
													? ""
													: item["pathname"],
											});

											localStorage.setItem("defaultActiveNav", index);
										} else {
											_dispatch(setLoginModal(true));
										}
									}
								}}
								style={{
									background: `url("${
										tabActive === index
											? item["activeImage"]
											: item["inactiveImage"]
									}") center center / contain no-repeat`,
									width: "100%",
									height: "68px",
								}}
							>
								{item["pathname"] == "/livechat" && unreadLivechat > 0 && (
									<div id="livechat-count" className="badge main-pulse">
										{unreadLivechat}
									</div>
								)}

								{item["pathname"] == "/promotion" &&
									totalPromoClaimable > 0 && (
										<div id="bonus-count" className="badge main-pulse">
											{totalPromoClaimable}
										</div>
									)}
							</div>
						);
					})}
			</div>

			{/* /////////t3 mobile menu pop.up */}
			{openMobileSideMenu && (
				<>
					<div className="t3-mobile-side-menu-container">
						<div className="t3-m-sm-header">
							<div className="t3-logo">
								<img src={logo} className="img-responsive" alt="logo" />
							</div>
							<div
								className="t3-close-modal"
								onClick={() => setOpenMobileSideMenu(!openMobileSideMenu)}
							>
								<VIcon icon={themeIcons.close} className="vicon" />
							</div>
						</div>
						<div className="t3-mobile-side-menu-content">
							<div className="t3-sidemenu-box">
								{sideMenuList &&
									sideMenuList.length > 0 &&
									map(sideMenuList, (item, index) => {
										return (
											<div
												key={index}
												className={"t3-sidemenu-item " + "t3-s-" + item["id"]}
												onClick={() => {
													if (heartBeat) {
														setOpenMobileSideMenu(!openMobileSideMenu);
														_history.replace({
															pathname: stringIsNullOrEmpty(item["pathname"])
																? ""
																: item["pathname"],
															state: {
																reference: item["iconName"],
															},
														});
														if (item["id"] == 8) {
															localStorage.setItem("defaultActiveNav", 2);
														} else if (item["id"] == 9) {
															localStorage.setItem("defaultActiveNav", 1);
														} else {
															localStorage.setItem("defaultActiveNav", 0);
														}
													} else {
														if (item["pathname"] == "/recent-games") {
															_dispatch(
																showMessage({
																	type: AlertTypes._WARNING,
																	content: t("PLEASE_LOGIN_FIRST"),
																	onConfirm: () => {},
																})
															);
														} else {
															setOpenMobileSideMenu(!openMobileSideMenu);
															_history.replace({
																pathname: stringIsNullOrEmpty(item["pathname"])
																	? ""
																	: item["pathname"],
																state: {
																	reference: item["iconName"],
																},
															});

															if (item["id"] == 8) {
																localStorage.setItem("defaultActiveNav", 2);
															} else {
																localStorage.setItem("defaultActiveNav", 0);
															}
														}
													}
												}}
											>
												<div className="first">
													<img
														src={item["iconImage"]}
														className="img-responsive"
														alt={item["iconName"]}
													/>
												</div>
												<h6>{t(item["iconName"])}</h6>
											</div>
										);
									})}
							</div>
						</div>
						<div className="t3-msm-social-box">
							<h5 className="text-center">{t("SOCIAL_MEDIA")}</h5>
							<div className="t3-sm-box">
								{channels &&
									channels.length &&
									map(channels, (channel, index) => {
										return (
											<div
												key={index}
												className="t3-sm-item"
												onClick={() => {
													if (!stringIsNullOrEmpty(channel.cta)) {
														handleClick(channel);
													}
												}}
												style={{ cursor: "pointer" }}
											>
												<div className="first">
													<img
														src={channel.image}
														className="img-responsive"
														alt={channel.description}
													/>
												</div>
											</div>
										);
									})}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Footer;
